.Container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 100px 32px;
  max-width: 1280px;
}

.BodyContainer {
  display: flex;
  width: 100%;
}

.LeftContainer {
  width: 100%;
}

.RightContainer {
  min-width: 280px;
  margin-left: 32px;
}

.SectionTitleText {
  font-family: Lato;
  font-style: normal;
  font-weight: bold;
  font-size: 32px;
  line-height: 32px;

  display: flex;
  align-items: center;
  letter-spacing: 0.2px;
}

@media only screen and (max-width: 767px) {
  .BodyContainer {
    flex-direction: column;
  }

  .RightContainer {
    width: 100%;
    margin: 28px 0px;
  }
}
