.NavBar {
  background-color: white !important;
  color: black;
  min-height: 80px;
  padding: 10px;
}

.NavBarContent {
  display: flex;
  justify-content: center;
  height: 100%;
}

.Links {
  display: flex;
  gap: 30px;
}

.Link {
  color: black;
  text-decoration: none;
  font-family: Lato;
  font-weight: bold;
  font-size: 20px;

  display: flex;
  flex-direction: column;
  gap: 2px;
  align-items: center;
  cursor: pointer;
}

@media only screen and (max-width: 767px) {
  .Logo {
    display: flex;
    flex-direction: column;
    font-family: Lato;
    font-weight: bold;
    font-size: 18px;
    color: black;
    text-decoration: none;
  }

  .NavBarContent {
    justify-content: space-between;
  }

  .MenuDrawer {
    padding: 30px 15px;
    min-width: 200px;
    display: flex;
    flex-direction: column;
    gap: 20px;
  }

  .Link {
    font-size: 20px;
    align-items: flex-start;
  }
}
