.Container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  height: fit-content;
  padding: 28px 36px;
}

.TitleText {
  font-family: Lato;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.05em;
  text-transform: uppercase;

  color: #eb5757;
}

.BodyText {
  font-family: Lato;
  font-size: 16px;
  line-height: 19px;
}

.BodyText > p {
  margin: 4px 0px;
}
