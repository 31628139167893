.Container {
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 100px 32px;
  max-width: 1980px;
}

.SearchContainer {
  display: flex;
  width: 100%;
  justify-content: space-between;
  flex-wrap: wrap;
}

.FilterContainer {
  display: flex;
  align-items: center;
  gap: 10px;
}

.LabelText {
  font-family: Lato;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 24px;
}

.HeaderContainer {
  display: flex;
  width: 100%;
  height: fit-content;
  gap: 10px;
  align-items: center;
  padding: 20px 10px;

  font-family: Lato;
  font-weight: bold;
  font-size: 24px;
}

.InfoContainer {
  display: flex;
  width: 100%;
  gap: 10px;
  align-items: center;
  padding: 10px 15px;
  font-family: Lato;
  font-size: 20px;
  line-height: 22px;
}

.HeaderTitleSpan {
  width: 40%;
  word-wrap: break-word;
}

.HeaderInfoSpan {
  width: 15%;
  display: flex;
}

@media only screen and (max-width: 767px) {
  .Container {
    padding: 18px 32px;
  }
  .SearchContainer {
    flex-direction: column-reverse;
  }

  .FilterContainer {
    align-items: flex-start;
    flex-direction: column;
    width: 100%;
  }

  .LabelText {
    width: 100%;
    text-align: left;
  }

  .InfoContainer {
    flex-direction: column;
    align-items: flex-start;
    padding: 24px 32px;
    width: 85%;
  }

  .HeaderTitleSpan {
    width: 100%;
    font-weight: bold;
  }

  .HeaderInfoSpan {
    width: 100%;
  }
}
