.Container {
  width: 100%;
  height: fit-content;
  min-height: 130px;
  padding: 32px;
  /* display: flex;
  flex-direction: column; */
}

.MeetingName {
  font-family: Merriweather;
  font-style: italic;
  font-weight: 700;
  font-size: 36px;
  line-height: 36px;
}

.SectionHeader {
  font-family: Lato;
  font-weight: 700;
  font-size: 20px;
  line-height: 19px;
  letter-spacing: 0.05em;

  color: #eb5757;
}

.Text {
  font-family: Lato;
  font-size: 18px;
  line-height: 22px;
  text-align: left;
}

@media only screen and (max-width: 767px) {
  .MeetingName {
    font-size: 28px;
    line-height: 32px;
  }
}

@media only screen and (max-width: 767px) {
  .MeetingName {
    font-size: 22px;
    line-height: 28px;
  }
}
