.Container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  height: fit-content;
  padding: 28px;
  gap: 20px;
}

.TitleText {
  font-family: Lato;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.05em;

  color: #eb5757;
}

.LinkItemContainer {
  display: flex;
  gap: 10px;
  align-items: center;
  width: 100%;
  cursor: pointer;
  text-decoration: none;

  font-family: Lato;
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
  color: black;
}

.LinkItemIcon {
  height: 34px;
  width: 40px;
}
