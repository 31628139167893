.FooterDiv {
  display: flex;
  padding: 30px 50px;
  flex-direction: column;
  justify-content: center;
}

.Footer span.VerticalDivider {
  margin: 0 7px;
  font-weight: 300;
}

.Links {
  display: flex;
  justify-content: center;
  gap: 20px;
  width: 100%;
}

.Links > a {
  color: black;
  text-decoration: none;
}

.CopyRightDiv {
  text-align: center;
  padding: 5px 0px;
  font-style: normal;
  font-size: 14px;
  line-height: 24px;
  text-align: center;
}

@media only screen and (max-width: 768px) {
  .Footer {
    display: flex;
    flex-flow: column;
    align-items: center;
    font-size: 14px;
  }

  .Footer div {
    margin: 6px 0px;
  }
  .Footer a {
    margin: 10px 0;
  }
  .Footer span.VerticalDivider {
    display: none;
  }
  .CopyRightDiv {
    margin-top: 0px;
  }

  .Links {
    align-items: center;
    flex-direction: column;
  }
}
