.Container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;
  width: 100%;
  max-width: 600px;
}

.ButtonSize {
  width: 100%;
  height: 50px;
}

.TitleText {
  font-family: Lato;
  font-style: normal;
  font-weight: bold;
  font-size: 32px;
  line-height: 32px;
  display: flex;
  align-items: center;
  letter-spacing: 0.2px;
}

@media only screen and (max-width: 767px) {
  .Container {
    align-items: flex-start;
    max-width: none;
  }
}
