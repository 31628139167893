.Container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 100px 32px;
  max-width: 1280px;
}

.BodyContainer {
  display: flex;
  width: 100%;
}

.LeftContainer {
  width: 100%;
}

.BodyContainer > * > p {
  font-family: Lato;
  font-size: 22px;
}

.RightContainer {
  min-width: 280px;
  margin-left: 60px;
}

.SectionHeader {
  font-family: Lato;
  font-weight: bold;
  font-size: 36px;
  line-height: 32px;
  text-align: center;
  padding: 32px 0px;
}

.Verse {
  font-family: Lato;
  font-size: 24px;
  font-style: italic;
  line-height: 32px;
  text-align: center;
}

@media only screen and (max-width: 767px) {
  .Container {
    padding: 40px 32px;
  }

  .BodyContainer {
    flex-direction: column;
  }

  .RightContainer {
    width: 100%;
    margin: 28px 0px;
  }

  .Header {
    top: 130px;
    font-size: 24px;
  }
}
