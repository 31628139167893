.Container {
  width: 100%;
  height: 500px;
  padding: 24px 50px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  background-image: url("https://storage.googleapis.com/acafamily/images/cover.JPG");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: right bottom;
}

.TitleText {
  font-family: Lato;
  font-weight: bolder;
  font-size: 36px;
  text-align: left;
  color: white;
}

.ButtonContainer {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  gap: 15px;
}

@media only screen and (max-width: 767px) {
  .Container {
    padding: 18px;
  }

  .ButtonContainer {
    width: 100%;
    gap: 10px;
  }

  .ButtonSize {
    width: 100%;
  }

  .TitleText {
    font-size: 28px;
  }

  .Button {
    height: 32px !important;
  }
}

@media only screen and (max-width: 480px) {
  .TitleText {
    font-size: 22px;
  }
}
