.Container {
  display: flex;
  flex-direction: column;
  padding: 100px 32px;
  max-width: 1280px;
  width: 100%;
  font-family: Lato;
  font-size: 18px;
  line-height: 22px;
  gap: 16px;
}
