.Container {
  width: 100%;
  height: fit-content;
  min-height: 300px;
  padding: 28px 36px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  border-radius: 10px;
}

.TitleText {
  font-family: Lato;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.05em;
  text-transform: uppercase;

  color: #eb5757;
}

.AddrText {
  font-family: Merriweather;
  font-style: italic;
  font-weight: bold;
  font-size: 24px;
  line-height: 24px;

  color: #282e50;
}

.MapContainer {
  width: 100%;
  height: 200px;
}

.ButtonSize {
  width: 100%;
}
