.Container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.HeaderContainer {
  filter: blur(1.5px);
  -webkit-filter: blur(1.5px);
  height: 400px;
  width: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center 80%;
}

.Header {
  position: absolute;
  top: 220px;

  font-family: Lato;
  font-weight: bold;
  font-size: 36px;
  line-height: 32px;
  color: white;
}

@media only screen and (max-width: 767px) {
  .HeaderContainer {
    height: 250px;
    background-position: center center;
  }

  .Header {
    top: 130px;
    font-size: 24px;
  }
}
