.PaperContainer {
  height: fit-content;
  min-height: 40px;
}

.ButtonBasicStyle {
  border-radius: 4px;
  padding: 10px 24px;
  font-style: normal;
  font-weight: 600;
  font-weight: normal;
  font-size: 18px;
  line-height: 25px;
  height: fit-content;
  min-height: 40px;
  margin: 0px;
}

.Label {
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 16px;
  word-break: break-word;
}

@media only screen and (max-width: 480px) {
  .ButtonBasicStyle {
    padding: 8px 16px;
  }
  .Label {
    font-size: 14px;
  }
}
